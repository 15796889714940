<template>
  <div :style="style"></div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "default",
    },
  },
  computed: {
    style() {
      let height = 8;
      if (this.size === "large") {
        height = 16;
      }
      return {
        height: height + "px",
      };
    },
  },
};
</script>