<template>
  <div class="button" @click="$emit('click')">
    <img src="./button-bg.png" class="bg" alt="">
    <div class="text" :class="{ active }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less" scoped>
.btn-box {
  display: inline-block;
  width: 72px;
  height: 32px;
  cursor: pointer;
  box-sizing: border-box;
  padding: 1px 3px 3px 1px;

  overflow: hidden;
  background-size: cover;

  .text {
    height: 100%;
    width: 100%;
    text-align: center;
  }


}

.button {
  position: relative;
  cursor: pointer;

  .text {
    position: absolute;
    top: 2px;
    left: 2.5px;
    right: 5px;
    bottom: 6px;
    text-align: center;
    font-size: 1.2rem;
    color: #8DBBC6;
    font-size: 1.6rem;
  }

  .active {
    background: linear-gradient(133deg, #2D6FCC 0%, #11448C 100%);
    color: #fff;

  }
}
</style>
