<template>
  <div
    class="modal"
    v-if="visible"
    :style="{ 'min-width': width ? width : '65vw' }"
  >
    <i class="close iconfont icon-close" @click="$emit('close')"></i>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
    },
  },
};
</script>

<style lang="less" scoped>
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 999;

  background: rgba(9, 14, 33, 0.3);
  box-shadow: 0px 15px 18px 10px #040f20;
  border: 2px solid rgba(255, 255, 255, 0.36);
  backdrop-filter: blur(34px);

  .close {
    position: absolute;
    top: 1.5vh;
    right: 1.5vh;
    font-size: 3vh;
    cursor: pointer;
  }
}
</style>