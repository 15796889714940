import { fetchList as fetchMenu } from "@/api/setting/menu";
// import publicRoutes from "@/router/public-routes";
export default {
  namespaced: true,

  state() {
    return {
      user: window.localStorage.getItem("user")
        ? JSON.parse(window.localStorage.getItem("user"))
        : {}, // 用户基本信息
      menuList: [], // 用户菜单和按钮的原始数组数据
    };
  },

  getters: {
    // 不包含按钮的菜单数组
    pureMenu(state) {
      return state.menuList.filter((item) => item.type !== "button");
    },

    getCurrentRoute: (state) => (path) => {
      return state.menuList.find((item) => item.path === path);
    },
  },

  mutations: {
    setUser(state, payload) {
      window.localStorage.setItem("user", JSON.stringify(payload));
      state.user = payload;
    },
    setMenuList(state, payload) {
      state.menuList = payload;
    },
  },

  actions: {
    getMenu({ commit }, payload) {
      return new Promise((resolve, reject) => {
        console.log("get menu tree payload ", payload);
        fetchMenu({
          platform: "pc",
        })
          .then((res) => {
            if (Array.isArray(res)) {
              // 给角色绑定权限列表，不绑定菜单列表，在当前角色下注册了用户后，也就标明了该用户有了权限表，登陆后拿着所有的菜单表去跟权限交叉匹配。
              const resultList = [];

              res.forEach((item) => {
                const result = payload.find(
                  (element) => element.code === item.permission
                );
                if (result) {
                  resultList.push(item);
                }
              });

              const list = resultList.filter((item) => item.status === 1); // 这里的逻辑应该是过滤出正常的菜单
              // list.push(...publicRoutes);

              // 菜单原始数据
              window.localStorage.setItem("menuList", JSON.stringify(list));
              console.log(list);
              commit("setMenuList", list);

              // 左侧菜单
              resolve();
            }
          })
          .catch(() => {
            reject();
          });
      });
    },
  },
};
