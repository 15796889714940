<template>
  <div class="card">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.card {
  background: linear-gradient(180deg, rgba(2, 34, 85, 0) 0%, #003144 100%);

  border: 2px solid;
  border-image: linear-gradient(
      180deg,
      rgba(4, 161, 216, 0),
      rgba(5, 167, 222, 1)
    )
    2 2;
  padding: 2vh;
}
</style>