import { render, staticRenderFns } from "./input-group.vue?vue&type=template&id=11ccfddc&scoped=true&"
var script = {}
import style0 from "./input-group.vue?vue&type=style&index=0&id=11ccfddc&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11ccfddc",
  null
  
)

export default component.exports