<template>
  <div>
    <router-view />
  </div>
</template>


<script>
export default {
  data() {
    return {};
  },
};
</script>