<template>
  <div class="card">
    <div class="header">
      <img src="@/assets/title.png" alt="" />
      <span>{{ title }}</span>
    </div>
    <div class="body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>


<style lang="less" scoped>
.card {
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 2vh;
  }
  .body {
    padding: 2vh;
    background: linear-gradient(180deg, rgba(6, 27, 48, 0) 0%, #061b30 100%);
    border: 1px solid;
    border-image: linear-gradient(
        180deg,
        rgba(43, 111, 206, 0),
        rgba(40, 109, 205, 0.46)
      )
      1 1;
  }
}
</style>