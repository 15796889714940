export default [
  {
    path: "/login",
    component: () => import("@/views/login"),
  },
  {
    path: "/",
    component: () => import("@/layout"),
    children: [
      {
        path: "",
        redirect: "drainage/overview",
      },
      // 排水专题 园区总览
      {
        path: "drainage/overview",
        component: () => import("@/views/drainage/overview"),
        children: [
          {
            path: "",
            redirect: "overview",
          },
          {
            path: "overview",
            component: () => import("@/views/drainage/overview/overview"),
          },
          {
            path: "monitor",
            component: () => import("@/views/drainage/overview/monitor"),
          },
        ],
      },

      // 防汛防涝
      {
        path: "drainage/preventFlood",
        component: () => import("@/views/drainage/preventFlood"),
        children: [
          {
            path: "",
            redirect: "preventFlood",
          },
          {
            path: "preventFlood",
            component: () =>
              import("@/views/drainage/preventFlood/preventFlood"),
          },
          {
            path: "parameterDispose",
            component: () =>
              import("@/views/drainage/preventFlood/paramsDispose"),
          },
        ],
      },
      // 统计分析
      {
        path: "drainage/analysis",
        component: () => import("@/views/drainage/analysis"),
        children: [
          {
            path: "",
            redirect: "rainAmountTrend",
          },
          {
            path: "rainAmountTrend",
            component: () =>
              import("@/views/drainage/analysis/rainAmountTrend"),
          },
          {
            path: "waterLevelTrend",
            component: () =>
              import("@/views/drainage/analysis/waterLevelTrend"),
          },
        ],
      },
      // 应急管理
      {
        path: "drainage/emergancy",
        component: () => import("@/views/drainage/emergancy"),
        children: [
          {
            path: "",
            redirect: "riskPlan",
          },
          // 应急培训
          {
            path: "riskTraining",
            component: () => import("@/views/drainage/emergancy/risk-training"),
          },
          {
            path: "riskTraining/add",
            component: () =>
              import("@/views/drainage/emergancy/risk-training/add"),
          },
          {
            path: "riskTraining/edit",
            component: () =>
              import("@/views/drainage/emergancy/risk-training/edit"),
          },
          //应急物资
          {
            path: "riskMaterial",
            component: () => import("@/views/drainage/emergancy/risk-material"),
          },
          {
            path: "riskMaterial/add",
            component: () =>
              import("@/views/drainage/emergancy/risk-material/add"),
          },
          {
            path: "riskMaterial/edit",
            component: () =>
              import("@/views/drainage/emergancy/risk-material/edit"),
          },
          // 应急管理
          {
            path: "riskPlan",
            component: () => import("@/views/drainage/emergancy/risk-plan"),
          },
          {
            path: "riskPlan/add",
            component: () => import("@/views/drainage/emergancy/risk-plan/add"),
          },
          {
            path: "riskPlan/edit",
            component: () =>
              import("@/views/drainage/emergancy/risk-plan/edit"),
          },
          {
            path: "riskPlan/edit-facility",
            component: () =>
              import("@/views/drainage/emergancy/risk-plan/editFacility"),
          },
          // 应急队伍
          {
            path: "riskMember",
            component: () => import("@/views/drainage/emergancy/risk-member"),
          },
          {
            path: "riskMember/add",
            component: () =>
              import("@/views/drainage/emergancy/risk-member/add"),
          },
          {
            path: "riskMember/edit",
            component: () =>
              import("@/views/drainage/emergancy/risk-member/edit"),
          },
        ],
      },
      // 供水专题
      {
        // 渗透监测
        path: 'water-supply/permeate',
        component: () => import('@/views/water-supply/permeate')
      },
      {
        // 用水分析
        path: 'water-supply/analysis',
        component: () => import('@/views/water-supply/analysis')
      },

      /* {
        path: "waterSupply/monitor",
        component: () => import("@/views/waterSupply/pipe"),
      },
      {
        path: "waterSupply/disPatch",
        component: () => import("@/views/waterSupply/pipe"),
      },
      {
        path: "waterSupply/analysis",
        component: () => import("@/views/waterSupply/pipe"),
      }, */

      // 污水专题-污水站监测
      {
        path: "sewage/monitor",
        component: () => import("@/views/sewage"),
      },
      // 污水专题-智能分析
      {
        path: "sewage/analysis",
        component: () => import("@/views/sewage/analysis.vue"),
      },

      // 系统设置
      {
        path: "setting",
        redirect: "setting/log",
      },
      {
        path: "setting/log",
        component: () => import("@/views/setting/log"),
      },
      {
        path: "setting/permission",
        component: () => import("@/views/setting/permission"),
      },

      {
        path: "setting/menu",
        component: () => import("@/views/setting/menu"),
      },
      {
        path: "setting/menu/add",
        component: () => import("@/views/setting/menu/add"),
      },
      {
        path: "setting/menu/edit",
        component: () => import("@/views/setting/menu/edit"),
      },
      {
        path: "setting/role",
        component: () => import("@/views/setting/role"),
      },
      {
        path: "setting/role/add",
        component: () => import("@/views/setting/role/add"),
      },
      {
        path: "setting/role/edit",
        component: () => import("@/views/setting/role/edit"),
      },
      {
        path: "setting/user",
        component: () => import("@/views/setting/user"),
      },
      {
        path: "setting/user/add",
        component: () => import("@/views/setting/user/add"),
      },
      {
        path: "setting/user/edit",
        component: () => import("@/views/setting/user/edit"),
      },
      {
        path: "setting/data-dict",
        component: () => import("@/views/setting/data-dict"),
      },
      {
        path: "setting/data-dict/add",
        component: () => import("@/views/setting/data-dict/add"),
      },
      {
        path: "setting/data-dict/edit",
        component: () => import("@/views/setting/data-dict/edit"),
      },

      {
        path: "*",
        component: () => import("@/views/404"),
      },
    ],
  },
];
