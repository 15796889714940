import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./css/index.less";

import Card from "./components/card";
import SearchButton from "./components/search/button";
import SearchInputGroup from "./components/search/input-group";
import Modal from "./components/modal";
import Pagination from "./components/pagination";
import TableCard from "./components/table-card";
import Padding from "@/components/padding";
import Button from "@/components/button";
import DataDictFinder from "./components/data-dict-finder.vue";

import {
  Icon,
  Button as AButton,
  Radio,
  Checkbox,
  Slider,
  Space,
  Form,
  Input,
  Row,
  Col,
  Layout,
  message,
  Modal as AModal,
  Table,
  Select,
  InputNumber,
  FormModel,
  Tabs,
  Cascader,
  DatePicker,
  modal,
  Dropdown,
  Menu,
  Breadcrumb,
} from "ant-design-vue";

Vue.use(Icon)
  .use(Radio)
  .use(Checkbox)
  .use(Slider)
  .use(AButton)
  .use(Space)
  .use(Form)
  .use(Input)
  .use(Row)
  .use(Col)
  .use(Layout)
  .use(Table)
  .use(Select)
  .use(InputNumber)
  .use(FormModel)
  .use(Tabs)
  .use(Cascader)
  .use(DatePicker)
  .use(modal)
  .use(Dropdown)
  .use(Menu)
  .use(Breadcrumb);
message.config({
  maxCount: 2,
});

// 常用方法
import { selectFilterOption } from "@/utils/antdv";

Vue.prototype.$message = message;
Vue.prototype.$confirm = AModal.confirm;
Vue.prototype.$error = AModal.error;
Vue.prototype.$success = AModal.success;
Vue.prototype.$selectFilterOption = selectFilterOption;

Vue.component("Card", Card);
Vue.component("Modal", Modal);
Vue.component("SearchButton", SearchButton);
Vue.component("SearchInputGroup", SearchInputGroup);
Vue.component("Pagination", Pagination);
Vue.component("TableCard", TableCard);
Vue.component("Padding", Padding);
Vue.component("Button", Button);

Vue.component("DataDictFinder", DataDictFinder);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
