export default {

    namespaced: true,

    state() {
        return {
            roadTypeList: [],
            viewList: [],
        }
    },

    mutations: {
        setRoadTypeList(state, payload) {
            state.roadTypeList = payload
        },
        setViewList(state, payload) {
            state.viewList = payload
        },
    },



}