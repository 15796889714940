import Vue from "vue";
import Vuex from "vuex";

import map from "./modules/map";
import setting from "./modules/setting";
import auth from "./modules/auth";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    map,
    setting,
    auth,
  },
});
