import request from '../request'

export function fetchList(params) {
    return request({
        url: '/basic-service/dict/list',
        params
    })
}

export function add(data) {
    return request({
        url: '/basic-service/dict/add',
        method: 'POST',
        data,
    })
}

export function edit(data) {
    return request({
        url: '/basic-service/dict/update',
        method: 'POST',
        data,
    })
}

export function remove(data) {
    return request({
        url: '/basic-service/dict/del',
        method: 'POST',
        data
    })
}


// 查询单个数据字典
export function fetchDetail(data) {
    return request({
        url: '/basic-service/dict/queryByCode',
        method: 'post',
        data
    })
}