<template>
  <div class="right pagination">
    <div class="center item">
      <i class="iconfont icon-arrow-left-bold"></i>
    </div>
    <div
      class="center item"
      v-for="item in 9"
      :key="item"
      :class="current === item ? 'active' : ''"
      @click="$emit('change', item)"
    >
      {{ item }}
    </div>
    <div class="center item">
      <i class="iconfont icon-arrow-right-bold"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    current: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="less" scoped>
.pagination {
  .item {
    width: 4vh;
    height: 4vh;
    background: linear-gradient(180deg, #022255 0%, #1865e0 100%);
    border-radius: 6px;
    border: 1px solid #4c77bc;
    margin-left: 0.6vw;
    cursor: pointer;
  }

  .active {
    background: linear-gradient(
      180deg,
      #00e2ff 0%,
      #008bff 35%,
      rgba(0, 97, 255, 0.15) 100%
    );
    box-shadow: 0px 2px 6px 0px #0a0528;
  }
}
</style>