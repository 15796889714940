

<template>
  <div class="left btn">
    <img :src="icon" alt="" />
    <span>{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
    },
    text: {
      type: String,
    },
  },
};
</script>

<style lang="less" scoped>
.btn {
  font-size: 1.5vh;
  line-height: 4vh;
  padding-right: 1vw;
  padding-left: 0.3vw;
  cursor: pointer;

  background: linear-gradient(
    180deg,
    rgba(121, 255, 254, 0.4) 0%,
    rgba(121, 255, 254, 0) 100%
  );
  border-radius: 2px;
  border: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(168, 230, 229, 1),
      rgba(121, 255, 254, 1)
    )
    1 1;

  img {
    height: 4vh;
  }
}
</style>